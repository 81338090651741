<template>
  <div class="works">
    <left-menu current="tme" />
    <div class="main">
      <el-form inline ref="form" label-width="80px" size="mini">
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item label="歌曲名">
              <el-input v-model="songName" clearable placeholder="请输入歌曲名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="艺人名">
              <el-input v-model="singer" clearable placeholder="请输入艺人名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="专辑名">
              <el-input v-model="albumName" clearable placeholder="请输入专辑名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ISRC">
              <el-input v-model="ISRC" clearable placeholder="请输入ISRC"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发行时间">
              <el-date-picker v-model="publicDate" value-format="yyyy-MM-dd" style="width: 193px"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联厂牌">
              <el-select v-model="companyState">
                <el-option
                    v-for="item in companyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台">
              <el-select v-model="platform">
                <el-option
                    v-for="item in platformOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16" style="text-align: right">
            <el-form-item>
              <el-button type="primary" @click="getList()">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <el-form inline ref="form1" label-width="80px" size="mini">
        <el-form-item label="">
          <el-select v-model="cycle" @change="getList()">
            <el-option
                v-for="item in cycleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-radio v-model="seq" label="hot">最热</el-radio>
          <el-radio v-model="seq" label="new">最新</el-radio>
          <el-radio v-model="seq" label="play_asc" v-show="cycle !== 'year'">升幅</el-radio>
          <el-radio v-model="seq" label="play_desc" v-show="cycle !== 'year'">降幅</el-radio>
        </el-form-item>
      </el-form>
      <el-table
          v-loading="loading"
          size="small"
          :data="tableList"
          style="width: 100%">
        <el-table-column
            type="index"
            label="序号"
            width="50">
        </el-table-column>
        <el-table-column
            label="趋势图">
          <template slot-scope="scope">
            <el-popover
                width="700"
                @show="showTrend(scope)"
                @hide="hideTrend(scope)"
                trigger="click">
              <trend-chart
                  :track-id="scope.row.trackId"
                  :cp-company-id="scope.row.cpCompanyId"
                  :cycle="cycle"
                  v-if="isShowChart[scope.$index]"
              ></trend-chart>
              <el-button slot="reference" type="text">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            label="数据周期"
            width="110">
          <template slot-scope="scope">
            {{scope.row.interval | interval}}
          </template>
        </el-table-column>
        <el-table-column
            label="歌曲信息"
            width="200">
          <template slot-scope="scope">
            <div class="track-info">
              <el-image class="track-info-photo" :src="scope.row.album.albumPhotoList[0].photoUrl"></el-image>
              <div class="track-info-message">
                <p>
                  <span class="text">{{scope.row.trackName}}</span>
                </p>
                <p>
                  <el-tooltip placement="top" :content="scope.row.singerName + ' - ' + scope.row.trackName">
                    <span class="text">{{scope.row.singerName}} - {{scope.row.trackName}}</span>
                  </el-tooltip>
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="srcVersion"
            label="歌曲版本">
        </el-table-column>
        <el-table-column
            label="语言">
          <template slot-scope="scope">
            {{scope.row.language | language}}
          </template>
        </el-table-column>
        <el-table-column
            label="发行时间"
            prop="publicTime"
            width="90">
        </el-table-column>
        <el-table-column
            prop="playCnt"
            width="90"
            label="播放指数">
        </el-table-column>
        <el-table-column
            prop="downloadCnt"
            label="下载指数">
        </el-table-column>
        <el-table-column
            prop="favCnt"
            label="收藏指数">
        </el-table-column>
        <el-table-column
            label="收藏增长指数">
          <template slot-scope="scope">
            {{ scope.row.favCntRate | cntRate }}
          </template>
        </el-table-column>
        <el-table-column
            prop="commentCnt"
            label="评论数">
        </el-table-column>
        <el-table-column
            label="评论增长指数">
          <template slot-scope="scope">
            {{ scope.row.commentCntRate | cntRate }}
          </template>
        </el-table-column>
        <el-table-column
            prop="qqRank"
            label="Q音热歌榜最佳排名">
        </el-table-column>
        <el-table-column
            prop="kgRank"
            label="酷狗Top500榜最佳排名">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :current-page.sync="pageNum"
          :total="total"
          style="text-align: right"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu.vue'
import TrendChart from "../components/TrendChart"
export default {
  components: {
    LeftMenu,
    TrendChart
  },
  name: "TME",
  data () {
    return {
      loading: false,

      isShowChart:[],
      tableList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,

      singer: '',
      albumName: '',
      ISRC: '',
      songName: '',
      seq: 'hot',

      chart: [],
      publicDate: '',

      companyState: '1049035',
      // 公司厂牌id(1049035:嗨库;7129:伯乐;105737:冰曲;1238057:坚诚;103596:鲸鱼)
      companyOptions: [
        {
          value: '1049035',
          label: '嗨库'
        },
        {
          value: '7129',
          label: '伯乐'
        },
        {
          value: '105737',
          label: '冰曲'
        },
        {
          value: '1238057',
          label: '坚诚'
        },
        {
          value: '103596',
          label: '鲸鱼'
        }
      ],

      cycle: 'week',
      cycleOptions: [
        {
          value: 'year',
          label: '近两年'
        },
        {
          value: 'week',
          label: '近一周'
        },
        {
          value: 'mon',
          label: '近一个月'
        },
      ],

      platform: 'tme',
      platformOptions: [
        {
          value: 'tme',
          label: '全平台'
        },
        {
          value: 'qq',
          label: 'QQ音乐'
        },
        {
          value: 'kg',
          label: '酷狗音乐'
        },
        {
          value: 'kw',
          label: '酷我音乐'
        }
      ]
    }
  },
  methods: {
    getList(){
      if (this.cycle === 'year' && (this.seq === 'play_asc' || this.seq === 'play_desc')) this.seq = 'hot'
      this.loading = true
      this.$post('/api/tmeplatform/songlist', {
        platform: this.platform,
        company: this.companyState,
        cycle: this.cycle,
        public_time: this.publicDate,
        sort: this.seq,
        singer_name: this.singer,
        track_name: this.songName,
        album_name: this.albumName,
        isrc: this.ISRC,
        page: this.pageNum - 1,
        // num: this.pageSize
      }, {
        baseURL: process.env.VUE_APP_BASE_API_ADMIN
      }).then(r => {
        this.loading = false
        const t = r.data ? r.data.list || [] : []
        this.isShowChart = []
        t.forEach(a => {
          this.isShowChart.push(false)
          a.album = a.album === 'null' ? {albumPhotoList: [{photoUrl: ''}]} : a.album
          if(a.album.albumPhotoList.length === 0)
            a.album.albumPhotoList = [{photoUrl: ''}]
        })
        this.tableList = t;
        this.total = r.data ? r.data.total : 0
      }).catch(e => {
        this.loading = false
        console.error(e)
      })
    },
    showTrend(value){
      this.isShowChart.splice(value.$index, 1, true)
    },
    hideTrend(value){
      this.isShowChart.splice(value.$index, 1, false)
    }
  },
  created() {
  },
  mounted() {
    this.getList()
  },
  watch: {
    pageNum(){
      this.getList()
    },
    seq(){
      this.getList()
    }
  },
  filters: {
    language (value) {
      // 语言 [-1未知；0普通话；1粤语；2闽南语；3日语；4韩语；5英语；7其他；9纯音乐；24越南语；25藏语；]
      switch (value){
        case -1: return '未知'
        case 0: return '普通话'
        case 1: return '粤语'
        case 2: return '闽南语'
        case 3: return '日语'
        case 4: return '韩语'
        case 5: return '英语'
        case 7: return '其他'
        case 9: return '纯音乐'
        case 24: return '越南语'
        case 25: return '藏语'
      }
    },
    cntRate (value) {
      if (Number(value) <= 0)
        return '0.00'
      else
        return Number(value).toFixed(2)
    },
    interval (value) {
      if (!value) return ''
      const t = value.split('')
      t[8] = ' 至 '
      t.splice(4, 0, '-')
      t.splice(7, 0, '-')
      t.splice(15, 0, '-')
      t.splice(18, 0, '-')

      return t.join('')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/works.scss';
p{
  margin: 0;
  padding: 0;
}
.main{
  padding: 32px;
  box-sizing: border-box;
}
.track-info-photo{
  width: 42px;
  height: 42px;
  margin-right: 10px;
  background-size: cover;
  background-position: 50% center;
}
.track-info-message{
  width: 110px;
}
.text{
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.track-info{
  display: flex;
}
.chart{
  width: 500px;
  height: 300px;
}
::v-deep .el-form-item__label{
  font-size: 14px;
  font-weight: normal;
}
</style>
